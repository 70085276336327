import React,{useEffect} from "react";
import {CONTACT_INFO, APP_COLOR} from '../Constant'


const PrivacyPolicy = () => {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-sans text-gray-800 p-6 md:p-10">
      <h1 style={{color:APP_COLOR}} className="text-2xl font-bold  mb-4">Privacy Policy</h1>
      <p className="mb-4">
        <strong>Effective Date:</strong> 28th November, 2024
      </p>
      <p className="mb-4">
        Welcome to the Canaffor Corporation website. We value your privacy and
        are committed to protecting your personal information. This Privacy
        Policy outlines how we collect, use, and safeguard the data you provide
        while using our website.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold  mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p className="mb-2">
        We collect personal information only when you voluntarily submit it
        through the <strong>Contact Us</strong> form. The details collected
        include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Your name</li>
        <li>Your contact details (email address or phone number)</li>
      </ul>
      <p className="mb-4">
        In addition, we use analytics tools to collect non-personal data such
        as website traffic patterns and user behavior to enhance our website's
        functionality.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold  mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p className="mb-2">The information you provide is used for the following purposes:</p>
      <ul className="list-disc list-inside mb-4">
        <li>To respond to your inquiries or requests</li>
        <li>To contact you regarding your requirements</li>
      </ul>
      <p className="mb-4">
        We do not share, sell, or disclose your personal information to any
        third parties under any circumstances.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold mt-6 mb-2">
        3. Data Storage and Security
      </h2>
      <p className="mb-4">
        Your data is stored securely within our email system. We employ strict
        security measures to protect your personal information from
        unauthorized access, misuse, or disclosure. However, please be aware
        that no method of transmission over the internet or electronic storage
        is 100% secure.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold mt-6 mb-2">
        4. Cookies and Analytics
      </h2>
      <p className="mb-2">Our website uses cookies and analytics tools to track non-personal data such as:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Website traffic</li>
        <li>Browsing patterns</li>
      </ul>
      <p className="mb-4">
        These tools help us understand user behavior and improve our services.
        Cookies do not collect personally identifiable information, and you can
        disable them in your browser settings if desired.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold mt-6 mb-2">
        5. Your Rights
      </h2>
      <p className="mb-2">You have the following rights concerning your personal data:</p>
      <ul className="list-disc list-inside mb-4">
        <li>To request access to the information we have collected about you</li>
        <li>To request the deletion of your personal information</li>
      </ul>
      <p className="mb-4">
        To exercise these rights, please contact us at{" "}
        <a href="mailto:info@canaffor.com" className="text-blue-600 underline">
        {CONTACT_INFO.info_email}
        </a>
        .
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold  mt-6 mb-2">
        6. Children’s Privacy
      </h2>
      <p className="mb-4">
        Our website is not designed for or directed towards children under the
        age of 18. We do not knowingly collect personal information from
        children. If you believe a child has provided us with their data, please
        contact us so we can remove it.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold mt-6 mb-2">
        7. Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Significant changes
        will be communicated via email if you have submitted your contact
        details through our website. Please review this page periodically for
        updates.
      </p>
      <h2 style={{color:APP_COLOR}}  className="text-xl font-semibold mt-6 mb-2">
        8. Contact Us
      </h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or how
        we handle your information, please contact us at:
      </p>
      <p className="mb-2">
        <strong>Email:</strong>{" "}
        <a href="mailto:info@canaffor.com" className="text-blue-600 underline">
          {CONTACT_INFO.info_email}
        </a>
      </p>
      <p>
        <strong>Phone:</strong> {CONTACT_INFO.privacy_policy_phone_no}
      </p>
    </div>
  );
};

export default PrivacyPolicy;

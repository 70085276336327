import React, { useState } from "react";

const ChatSection = () => {
    const [openAccordion, setOpenAccordion] = useState("");

    const toggleAccordion = (section) => {
        setOpenAccordion(openAccordion === section ? null : section);
    };

    const accordionItems = [
        {
            title: "User-to-user chat",
            content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
        },
        {
            title: "Business-to-user chat",
            content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
        },
        {
            title: "Helpful notifications",
            content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
        },
        {
            title: "Customer support",
            content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
        },
        {
            title: "Advisory services",
            content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
        },
        {
            title: "Community",
            content:
                "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable",
        },
    ];

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-gray-50">
            
            <div className="w-full md:w-1/2 p-6">
                {accordionItems.map((item) => (
                    <div
                        key={item.title}
                        className="border-b border-gray-200 py-4 cursor-pointer"
                    >
                        <div
                            className="flex justify-between items-center"
                            onClick={() => toggleAccordion(item.title)}
                        >
                            <h2 className="text-lg font-semibold text-gray-800">
                                {item.title}
                            </h2>
                            <span className="text-gray-500">
                                {openAccordion === item.title ? "−" : "+"}
                            </span>
                        </div>
                        {openAccordion === item.title && (
                            <p className="text-sm text-gray-600 mt-2">{item.content}</p>
                        )}
                    </div>
                ))}
            </div>

            
            <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
                <img
                    src="" 
                    alt="Chat Preview"
                    className="max-w-full h-auto rounded-lg shadow-lg"
                />
            </div>
        </div>
    );
};

export default ChatSection;

import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import {handleRedirect} from '../Common_function'

import { APP_COLOR, Email_keys, SOCIAL_MEDIA_LINKS, COMPANY_DETAILS, CONTACT_INFO } from "../Constant";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
// import Analytics from '../Analytics';

const Contact = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState("");
  const [showPop, setShowPop] = useState(false);

  const [focusStates, setFocusStates] = useState({
    name: false,
    email: false,
    message: false,
  });

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.from_name.value;
    const email = form.current.from_email.value;
    const message = form.current.message.value;

    if (!name || !email || !message) {
      setStatusMessage("All fields are required.");
      showPopup();
      return;
    }

    // Basic email format check
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setStatusMessage("Please enter a valid email address.");
      showPopup();
      return;
    }

    ReactGA.event({
      category: 'Lead Generation',           // Event category (e.g., 'Ecommerce')
      action: 'form_submitted',             // Event action (e.g., 'purchase')
      label: 'Form Submitted',       // Event label (optional)                // Event value (optional, e.g., price or amount)
    })
    emailjs.sendForm(`${Email_keys.service_key}`, `${Email_keys.template_key}`, form.current, {
      publicKey: `${Email_keys.public_key}`,
    })
      .then(
        () => {
          setStatusMessage("Your message has been sent successfully!");
          showPopup();
          form.current.reset();
        },
        (error) => {
          setStatusMessage(`Failed to send message: ${error.text}`);
          setShowPop(true);
        }
      );
  };

  const showPopup = () => {
    setShowPop(true);

    // Auto-close popup after 3 seconds
    setTimeout(() => {
      setShowPop(false);
    }, 3000);
  };

  const closePopup = () => {
    setShowPop(false);
  };

  const handleFocusChange = (field, isFocused) => {
    setFocusStates((prev) => ({ ...prev, [field]: isFocused }));
  };
  // if (window.gtag == undefined){
  //   Analytics()
  // }
  // window.gtag('event', 'conversion', {
  //   'send_to': 'AW-605399156/5uf_CKblrOwZEPTQ1qAC',
  //   'value': 1.0,
  //   'currency': 'INR'
  // });
  return (
    <>
      <div className="flex justify-center w-full items-center min-h-screen #333333 mt-10 px-4 md:px-10 lg:px-20">
        <div className="flex flex-col lg:flex-row w-full flex-1 justify-between mx-auto lg:w-4/5 xl:w-3/5 gap-10 lg:gap-15">
          
          {/* Left Section */}
          <div className="w-full lg:w-1/2 space-y-6 mb-8 lg:mb-0 lg:pr-8">
            <h2 className="text-3xl lg:text-5xl text-gray-800 font-bold">Customer Support</h2>
            <p className="text-xl lg:text-2xl font-bold text-gray-800">
              Email: <a href={`mailto:${CONTACT_INFO.info_email}`} style={{ color: APP_COLOR }}>{CONTACT_INFO.info_email}</a>
            </p>
            <div>
              <h3 className="text-xl lg:text-2xl font-bold text-gray-800">Find us on</h3>
              <div className="flex space-x-4 mt-2">
                <Link to={SOCIAL_MEDIA_LINKS.linkedin}><i style={{ color: APP_COLOR }} className="fab fa-linkedin fa-2x"></i></Link>
                <Link to={SOCIAL_MEDIA_LINKS.instagram}><i style={{ color: APP_COLOR }} className="fab fa-instagram fa-2x"></i></Link>
                <Link to={SOCIAL_MEDIA_LINKS.facebook}><i style={{ color: APP_COLOR }} className="fab fa-facebook fa-2x"></i></Link>
                <Link to={SOCIAL_MEDIA_LINKS.twitter}><i style={{ color: APP_COLOR }} className="fab fa-twitter fa-2x"></i></Link>
                <Link to={SOCIAL_MEDIA_LINKS.youtube}><i style={{ color: APP_COLOR }} className="fab fa-youtube fa-2x"></i></Link>
              </div>
            </div>
            <div>
              <h3 className="text-xl lg:text-2xl font-bold text-gray-800">Corporate Office</h3>
              <p className="text-gray-600">We are headquartered in {COMPANY_DETAILS.address}</p>
              <button style={{ backgroundColor: APP_COLOR }} className="mt-4 px-4 py-2 text-white rounded-lg"
                onClick={() => handleRedirect(CONTACT_INFO.address_location_key)}>Get Directions</button>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-full lg:w-1/2 bg-gray-100 p-6 rounded-lg shadow-2xl max-w-md mx-auto lg:mx-0">
            <h3 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-4">Get in touch</h3>
            <form ref={form} onSubmit={sendEmail} className="space-y-4">
              <input
                onFocus={() => handleFocusChange('name', true)}
                onBlur={() => handleFocusChange('name', false)}
                style={{
                  border: focusStates.name ? `1px solid ${APP_COLOR}` : '',
                  outline: 'none',
                }}
                type="text"
                name="from_name"
                placeholder="Enter Name"
                className="w-full p-3 bg-white border border-gray-300 rounded-lg focus:outline-none"
              />
              <input
                onFocus={() => handleFocusChange('email', true)}
                onBlur={() => handleFocusChange('email', false)}
                style={{
                  border: focusStates.email ? `1px solid ${APP_COLOR}` : '',
                  outline: 'none',
                }}
                type="email"
                name="from_email"
                placeholder="Enter Email Address"
                className="w-full p-3 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
              />
              <textarea
                onFocus={() => handleFocusChange('message', true)}
                onBlur={() => handleFocusChange('message', false)}
                style={{
                  border: focusStates.message ? `1px solid ${APP_COLOR}` : '',
                  outline: 'none',
                }}
                name="message"
                placeholder="Enter Message"
                className="w-full p-3 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
                rows="5"
              ></textarea>
              <button style={{ backgroundColor: APP_COLOR }} type="submit" value="send" className="text-white font-medium rounded-full text-sm px-5 py-2.5 text-center">
                Submit
              </button>
            </form>
            <p className="text-sm text-gray-500 mt-4">
              By contacting us you agree to the <Link to="#" style={{ color: APP_COLOR }}>Terms and Conditions</Link> and <Link to="#" style={{ color: APP_COLOR }}>Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>

      {/* Popup for Success or Error Message */}
      {showPop && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 transition-opacity duration-300">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold text-gray-800">{statusMessage}</p>
            <button onClick={closePopup} className="mt-4" style={{ backgroundColor: APP_COLOR, color: 'white', padding: '10px 20px', borderRadius: '5px' }}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;

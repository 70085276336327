import React from 'react';

const SectionWidget = ({ title, subtitle, buttonText, imageSrc, onButtonClick, bgColor }) => {
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className="flex flex-col md:flex-row items-center justify-between w-full px-6 md:px-28 h-auto md:h-[80vh]"
    >
      {/* Image Section */}
      <div className="flex-1 flex justify-center items-center py-6 md:py-0 order-1 md:order-2">
        <img
          src={imageSrc}
          alt="Section Banner"
          className="w-full max-w-sm md:max-w-lg h-auto object-cover"
          loading="lazy"
        />
      </div>

      {/* Text Section */}
      <div className="flex-1 space-y-4 text-center md:text-left py-10 md:py-20 flex flex-col justify-center items-center md:items-start order-2 md:order-1">
        {/* H1 for large screens covers 2 lines */}
        <h1 className="text-3xl md:text-5xl font-bold text-black leading-snug md:leading-snug md:max-w-[20ch]">
          {title}
        </h1>
        <p className="text-base md:text-lg text-black">
          {subtitle}
        </p>
        <div className="flex justify-center md:justify-start">

          <button
            onClick={onButtonClick}
            className="bg-white text-black font-semibold rounded-full shadow-md hover:bg-gray-100 text-center flex items-center justify-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "180px",
              padding: "1rem 1.5rem",
              lineHeight: "1.2",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionWidget;

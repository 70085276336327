import React, { useEffect } from 'react';
import AboutUs from '../About/Aboutus';
import Content from '../Content/Content';
import Matrices from '../Metrics/Metrics';
import VideoPlayer from './Youtube player/Youtube_Player';
import Testimonials from '../Testimonials/Testimonials';
import { useNavigate } from "react-router-dom";

import 'aos/dist/aos.css';
import AOS from 'aos';
import SectionWidget from '../Banner/Section';
import CarouselSection from './CarouselSection/CarouselSection';

function Home() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate('/contact');
  };
  return (
    <>
      <div data-aos="fade-up">
        <CarouselSection />
      </div>
      <div data-aos="fade-up">
        <AboutUs />
      </div>
      <div data-aos="fade-up">
        <Content />
      </div>
      <div>
        <SectionWidget
          title="Looking to Hire Talent?"
          subtitle="90 day free replacement for any exits 🥳"
          buttonText="Get in touch"
          imageSrc={`${process.env.PUBLIC_URL}/images/left.jpeg`}
          onButtonClick={handleNavigate}
          bgColor="#eecc00"
        />
      </div>
   
      <div data-aos="fade-up">
        <Matrices />
      </div>
      <div data-aos="fade-up">
        <Testimonials />
      </div>
      <div>
        <SectionWidget
          title="Professional Training for Employees"
          subtitle="Supercharge your team! ⚡️⚡️"
          buttonText="See Programs"
          imageSrc={`${process.env.PUBLIC_URL}/images/right.jpeg`}
          onButtonClick={handleNavigate}
          bgColor="#71cffd"
        />
      </div>
      <div data-aos="fade-up">
        <VideoPlayer />
      </div>
    </>
  );
}

export default Home;

import React from "react";
import { Link } from 'react-router-dom';
import './AboutUs.css';
import { images } from '../Constant'

function AboutUs() {
  return (
    <section className="about-section flex flex-col-reverse sm:flex-row items-center max-w-screen-xl mx-auto p-5 sm:p-10">
      <div className="about-content w-full sm:w-1/2 p-5">
        <div className="text">

          <h1 className="about-title">About Canaffor Corporation 🚀</h1>
          <p className="about-paragraph">
            Canaffor Corporation, is your one-stop solution for
            all things HR!
          </p>
          <p className="about-paragraph">
            We’re proud to be one of the fastest-growing HR companies in India 🌏,
            working with top Indian businesses and global MNCs across the world.
          </p>
          <br></br>
          <span className="about-heading2">
            We specialize in: </span>
          <div className="about-list">
            <p><span className="emoji">⚡️</span> Hiring Top Talent (IT & Non-IT)</p>
            <p><span className="emoji">👔</span> Executive Search & Recruitment</p>
            <p><span className="emoji">📚</span> Training & Certifications</p>
            <p><span className="emoji">💼</span> HR Outsourcing & Advisory</p>
            <p><span className="emoji">🎯</span> Recruitment & Training</p>
            <p><span className="emoji">⚖️</span> HR Advisory & Legal Compliance</p>
          </div>

          <div className="data">
            <Link to="/contact" className="button contact-button">
              <div className="dots_border"></div>
              <span className="text_button">Contact us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="image-container w-full sm:w-1/2 p-5">
        <img
          src={images.about_img}
          alt="About Us"
          className="about-image"
        />
      </div>
    </section>
  );
}

export default AboutUs;

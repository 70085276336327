
const faviconUrl = process.env.PUBLIC_URL + '/favicon.ico'

const APP_COLOR = "#2aee62";

document.documentElement.style.setProperty('--app-color', APP_COLOR);

const TrackingId = "G-6PWCQ7EFY0";

const images = {
  about_img: "/images/Imgur.png",
}

const videos = {
  HOME_YOUTUBE_VIDEO_ID: '6N412Hwkews',
}

const Email_keys = {
  service_key: 'service_g8pb1sj',
  template_key: 'template_jgoz3lc',
  public_key: 's0yfd2N5WZaqS_rWS',
}

const CONTACT_INFO = {
  cv_email: "cvs@canaffor.com",
  info_email: "info@canaffor.com",
  hr_advisiory_phone: "+91 7718889909",
  recruitment_supportPhone: "+91 7506661939",
  privacy_policy_phone_no:"+91 7506149122",
  address_location_key: "https://maps.app.goo.gl/34s1D6U3dv6RCyfJ7",
};

const SOCIAL_MEDIA_LINKS = {
  facebook: "https://www.facebook.com/CanafforCorporation",
  twitter: "https://x.com/canaffor",
  linkedin: "https://www.linkedin.com/company/canaffor-corporation",
  instagram: "https://www.instagram.com/canafforcorporation",
  youtube: "https://www.youtube.com/@canafforcorporation",
};

const COMPANY_DETAILS = {
  name: "Canaffor Corporation | For smarter HR Solutions",
  address: "9th floor Ijmima Tower, Malad West, Mumbai",
  website: "https://www.canaffor.com/",
};

const Job_apply = {
  web_portal: 'https://canaffor-jobportal.web.app/',
};

export { APP_COLOR, images, videos, Email_keys, CONTACT_INFO, SOCIAL_MEDIA_LINKS, COMPANY_DETAILS, Job_apply, faviconUrl, TrackingId }
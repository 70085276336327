import React, { useEffect, useState, useRef } from 'react';
import { APP_COLOR } from "../Constant";

const Metrics = () => {
  const metricsData = [
    { value: 30, label: "Sectors Served" },
    { value: 200, label: "Clients Served" },
    { value: 40000, label: "Position Filled and counting!" },
    { value: 20000, label: "Social Media Reached" },
  ];

  return (
    <div
  style={{ backgroundColor: "#ffffff" }}
  className="w-full px-4 m-5 md:px-8 lg:px-24 py-10 bg-gray-100"
>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 items-center relative">
    {metricsData.map((metric, index) => (
      <div key={index} className="flex items-center justify-center relative">
        <MetricCounter
          value={metric.value}
          label={metric.label}
          delay={index * 200}
        />

        {/* Vertical divider spanning the full height of the parent */}
        {index < metricsData.length - 1 && (
          <div className="hidden md:block absolute top-0 bottom-0 right-0 w-px bg-gray-300"></div>
        )}
      </div>
    ))}
  </div>
</div>

  );
};

const MetricCounter = ({ value, label, delay }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once animation has started
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const end = value;
      const duration = 1600;
      const increment = end / (duration / 20);

      const counter = setInterval(() => {
        start += increment;
        if (start >= end) {
          clearInterval(counter);
          start = end;
        }
        setCount(Math.round(start));
      }, 20);

      return () => clearInterval(counter);
    }
  }, [isVisible, value]);

  const formatCount = () => {
    if (value >= 1000000000) return `${parseInt(count / 1000000000)}B+`;
    if (value >= 1000000) return `${parseInt(count / 1000000)}M+`;
    if (value >= 1000) return `${parseInt(count / 1000)}K+`;
    return `${count}+`;
  };
  
  return (
    <div ref={counterRef} className="text-center opacity-0 animate-fadeIn" style={{ animationDelay: `${delay}ms` }}>
      <h2 style={{ color: APP_COLOR }} className="text-3xl md:text-5xl font-bold">{formatCount()}</h2>
      <p className="text-gray-600 text-lg">{label}</p>
    </div>
  );
};

export default Metrics;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './consulting.css';

const servicesData = [
  {
    icon: "💼",
    title: "Boosting Employee Morale & Reducing Attrition",
    description:
      "High attrition and low employee morale can drain a company’s resources and hinder growth. We help businesses create engaging work environments, enhance leadership practices, and implement retention strategies to foster a committed, motivated workforce.",
  },
  {
    icon: "📈",
    title: "Scaling Your Start-Up with Confidence",
    description:
      "As a start-up, scaling can feel like a daunting challenge. We partner with you to design effective growth strategies, streamline processes, and build the HR infrastructure necessary to scale smoothly while maintaining operational stability.",
  },
  {
    icon: "💡",
    title: "Maximizing Profitability in Stable Companies",
    description:
      "A company may be stable but still lack profitability due to inefficiencies or misaligned strategies. Our team helps identify areas for optimization, from resource allocation to strategic planning, to ensure your business is achieving maximum returns.",
  },
  {
    icon: "💵",
    title: "Improving Profitability and Employee Engagement",
    description:
      "Even the most profitable companies can struggle with employee satisfaction and retention. We focus on aligning your HR practices with your business goals to balance profitability with a thriving, engaged workforce.",
  },
  {
    icon: "📉",
    title: "Breaking Through Performance Stagnation",
    description:
      "Stagnation can prevent your business from reaching its full potential. We assess internal processes, streamline operations, and implement performance management systems that drive productivity and foster continuous improvement.",
  },
  {
    icon: "🔄",
    title: "Building Teams for New Growth Initiatives",
    description:
      "Expanding or launching new initiatives requires strategic team redeployment and leadership. Our consultants work with you to restructure and align teams to drive new projects while ensuring optimal resource utilization.",
  },
  {
    icon: "⚖️",
    title: "Managing Manpower Surplus Effectively",
    description:
      "Excessive manpower can strain resources and hinder productivity. We help organizations assess workforce needs, optimize staffing levels, and realign roles to ensure a lean, efficient operation without compromising performance.",
  },
  {
    icon: "👥",
    title: "Addressing Staffing Shortages & Stagnation",
    description:
      "Understaffing can lead to stagnation and missed opportunities. We assist in identifying skill gaps, recruiting talent, and redesigning workflows to ensure your team has the necessary resources and capabilities for growth.",
  },
  {
    icon: "📊",
    title: "Unlocking Hidden Potential in Your Workforce",
    description:
      "High pay doesn’t always correlate with high productivity. We help identify barriers to performance, improve work processes, and provide coaching to unlock the full potential of your employees, driving better results.",
  },
  {
    icon: "🎯",
    title: "Achieving Growth According to Your Vision",
    description:
      "When a company isn’t growing as envisioned by its leaders, it’s often due to misalignment in strategy or execution. We help refine your business plan, align HR practices, and provide leadership coaching to ensure that your vision is translated into measurable success.",
  },
  {
    icon: "😊",
    title: "Enhancing Customer Satisfaction & Loyalty",
    description:
      "Customer satisfaction doesn’t just depend on external efforts; internal inefficiencies can create customer dissatisfaction. We work with you to optimize internal processes, align teams, and improve service delivery to enhance the customer experience.",
  },
  {
    icon: "🔑",
    title: "Retaining Skilled Employees for Long-Term Success",
    description:
      "High-skilled employees are crucial to business success but can be hard to retain. We help you design retention strategies, from career development programs to competitive benefits, to ensure top talent stays engaged and committed.",
  },
  {
    icon: "📚",
    title: "Developing Employee Skills & Capabilities",
    description:
      "Without continuous skill development, your workforce may struggle to keep up with changing demands. We create tailored training and development programs that equip employees with the skills they need to succeed in their current and future roles.",
  },
  {
    icon: "⚙️",
    title: "Delegating Effectively to Middle Management",
    description:
      "Many companies struggle with empowering middle management to take on critical leadership roles. We guide you in establishing effective delegation practices, enhancing management capabilities, and ensuring that workloads are balanced across your leadership team.",
  },
  {
    icon: "🎯",
    title: "Attracting Top Talent in a Competitive Market",
    description:
      "In today’s competitive market, attracting the right talent can be a challenge. We help refine your employer brand, improve your recruitment strategies, and develop a streamlined hiring process to bring in the best candidates for your organization.",
  },
  {
    icon: "🚀",
    title: "Driving Innovation with Strategic HR Practices",
    description:
      "Innovation often starts with a strong, forward-thinking workforce. We work with you to create a culture of innovation, offering the tools and strategies necessary to foster creative thinking and bring new products and services to market.",
  },
  {
    icon: "📉",
    title: "Maximizing ROI on Existing Skillsets",
    description:
      "Your employees may already have the skills you need, but leveraging them effectively is key. We assess skill gaps, realign teams, and optimize workflows to ensure that your current workforce is delivering maximum value.",
  },
  {
    icon: "🌟",
    title: "Maintaining Consistent Customer Satisfaction",
    description:
      "Ensuring customer satisfaction requires consistent performance from your team. We help improve internal operations, refine customer service processes, and enhance employee engagement to guarantee that your customers are consistently delighted.",
  },
];

const CarouselService = () => {
  return (
    <div className="services-container">
      <h1 className="text-3xl font-bold text-center mb-6 leading-snug">
        Our One Focus: Efficiency, and Employee Success
      </h1>
      <p className="services-description">
        Whether you're a start-up struggling to scale, a stable company seeking profitability, or an organization facing high attrition and low productivity, we provide comprehensive HR consulting services tailored to your unique needs.
        From improving employee morale and retention to optimizing workforce performance and aligning HR strategies with business goals, we partner with you to unlock growth, boost employee engagement, and drive sustainable success.
        <br /><br />
      </p>
      <div className="services-carousel">
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          breakpoints={{
            320: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
        >
          {servicesData.map((service, index) => (
            <SwiperSlide key={index}>
              <div className="service-card">
                <div className="service-icon">{service.icon}</div>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev custom-nav-button">❮</div>
        <div className="swiper-button-next custom-nav-button">❯</div>
      </div>
    </div>
  );
};



export default CarouselService;

import React from 'react';
import CarouselService from './CarouselService';
import ChatSection from './ChatSection';




const Hr_consulting = () => {
  return (
    <>
      <CarouselService />
      <ChatSection/>
    </>

  )

};



export default Hr_consulting;

import React from 'react';
import YouTube from 'react-youtube';
import { videos } from '../../Constant';

const VideoPlayer = () => {
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      controls: 1,
      rel: 0,
      showinfo: 0,
      mute: 0,
      modestbranding: 1
    },
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-6" id='video-section'>
      <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg shadow-lg">
        <YouTube
          videoId={videos.HOME_YOUTUBE_VIDEO_ID}
          opts={opts}
          onReady={(event) => {
           
          }}
          className="absolute top-0 left-0 w-full h-full"
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
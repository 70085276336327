import React, { useEffect } from 'react';
import { TrackingId } from './Constant';

function Analytics() {
  useEffect(() => {
    // Add the gtag script to the document
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${TrackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', TrackingId);

    return () => {
      // Clean up the script if necessary
      document.head.removeChild(script);
    };
  }, []);

  return null; // No UI rendered by this component
}

export default Analytics;
